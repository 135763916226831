<template>
  <v-container fluid>
    <TableViewComponent
      title="Table acronymes"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un acronyme"
      itemLabel="acronyme"
      itemsLabel="acronymes"
      :items="acronyms"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet acronyme?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import { AcronymsService } from "@/service/dictionary/acronyms_service.js";
import * as exceptions from "@/service/exception_to_message.js";
import { defines as routes } from "@/router/defines.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableAcronyms",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      acronyms: [],

      /**le service d'accès aux acronymes */
      acronymsService: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.acronyms.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.acronyms = [];

        let acronyms = await this.acronymsService.getAll();

        acronyms.forEach((a) => {
          let acronym = JSON.parse(JSON.stringify(a));
          acronym.view = () => {
            this.$router.push(routes.acronyms.view.root + "/" + acronym.id);
          };

          //action de modification
          acronym.edit = () => {
            this.$router.push(routes.acronyms.edit.root + "/" + acronym.id);
          };

          acronym.delete = async () => {
            try {
              await this.acronymsService.delete(acronym.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'acronyme : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.acronyms.push(acronym);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Acronyme",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Signification",
          align: "start",
          sortable: true,
          value: "description",
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  }, // End method

  computed: {
    /**Retourne la liste des rôles attendus pour l'édition EditDictionary */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  }, // end computed

  mounted() {
    //on instancie les services
    this.acronymsService = new AcronymsService(this.$api.getAcronymsApi());

    // Chargement des datas
    this.load();
  },
};
</script>

<style></style>
